import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image/withIEPolyfill";
import { urlFor } from "../../lib/helpers";

const Image = (props) => {
  const { image, width, height } = props;
  const { x, y } = (image && image.hotspot) || {};

  if (!image) return null;
  if (!image.asset) return null;

  const objectPosition =
    x && y ? `${Math.round(x * 100)}% ${Math.round(y * 100)}%` : "50% 50%";

  const imageProps = {
    ...props,
  };

  if (image.asset.fluid) {
    return (
      <Img
        fluid={image.asset.fluid}
        objectFit="cover"
        objectPosition={objectPosition}
        {...imageProps}
      />
    );
  } else if (image.asset.fixed) {
    return (
      <Img
        fixed={image.asset.fixed}
        {...imageProps}
        objectPosition={objectPosition}
      />
    );
  } else if (image.asset.url) {
    return (
      <img
        src={urlFor(image).width(width).height(height).url()}
        style={{ objectPosition }}
        {...imageProps}
      />
    );
  } else {
    return null;
  }
};

Image.defaultProps = {
  width: 2000,
};

Image.propTypes = {
  image: PropTypes.object.isRequired,
};

export default Image;
